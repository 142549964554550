export enum BreakpointEnum {
  XXSmall = '(max-width: 480px)',
  XSmall = '(min-width: 481px) and (max-width: 767px)',
  HalfSmall = '(min-width: 768px) and (max-width: 900px)',
  Small = '(min-width: 768px) and (max-width: 1024px)',
  Medium = '(min-width: 1025px) and (max-width: 1241px)',
  Large = '(min-width: 1242px) and (max-width: 1920px)',
  XLarge = '(min-width: 1921px)',
}

export enum BreakpointTypeEnum {
  XXSmall = 'XXSmall',
  XSmall = 'XSmall',
  HalfSmall = 'HalfSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'configurator',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
    data: { title: 'UGOL.Авторизация' },
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: async () => (await import('./account/account.module')).AccountModule,
    data: { title: 'UGOL.Личный кабинет' },
  },
  {
    path: 'configurator',
    canActivate: [AuthGuard],
    loadChildren: async () =>
      (await import('./configurator/configurator.module')).ConfiguratorModule,
    data: { title: 'UGOL.Конфигуратор' },
  },
  {
    path: 'sketch',
    canActivate: [AuthGuard],
    loadChildren: async () => (await import('./sketch/sketch.module')).SketchModule,
    data: { title: 'UGOL.Дизайн' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
